import { ROUTES_PATH_CONFIG } from '@config/routes-config';
import { signIn, signInWithGoogle } from '@firebase/firebase-auth';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-export-i18n';
import { NextSeo } from 'next-seo';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { CenterContent, FormInput, FormSubmitButton, Headline, SolidInfoAlert } from '../components';
import { getCurrentUser } from '../firebase';
import { useLoadingAnimation } from '../hooks';
import googleLogo from '../images/google-logo-small.svg';
import { userDataState } from '../state';

import type { UserCredential } from 'firebase/auth';

type FormData = {
  email: string;
  password: string;
};

export default function LoginPage() {
  const [, setUserData] = useRecoilState(userDataState);
  const executeWithLoadingAnimation = useLoadingAnimation();
  const router = useRouter();
  const [error, setError] = useState<string>('');
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>();
  const { t } = useTranslation();

  const loginUserAndRedirect = async (loginUser: () => Promise<UserCredential>) => {
    try {
      await executeWithLoadingAnimation(async () => {
        await loginUser();
        const currentUser = await getCurrentUser();

        if (currentUser) {
          setUserData(currentUser);
        }
      });
      await router.push(ROUTES_PATH_CONFIG.frontpage);
    } catch {
      setError(t('error.login'));
    }
  };

  const onSubmit = handleSubmit(async ({ email, password }) => {
    await loginUserAndRedirect(() => signIn(email, password));
  });

  const onLoginWithGoogle = async () => {
    await loginUserAndRedirect(signInWithGoogle);
  };

  return (
    <div>
      <NextSeo title={t('metaTitle.login')} description={t('metaDescription.login')} />
      <Headline text={t('headline.login')} />
      <CenterContent containerClasses="mt-8">
        {error && <SolidInfoAlert text={error} containerClasses={'mb-4'} />}
        <form onSubmit={onSubmit}>
          <Controller
            name="email"
            control={control}
            defaultValue={''}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            }}
            render={({ field }) => (
              <FormInput
                customProps={{
                  id: 'email',
                  label: t('word.email'),
                  type: 'text',
                  hasErrors: !!errors.email,
                }}
                inputProps={field}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <FormInput
                customProps={{
                  id: 'password',
                  label: t('word.password'),
                  type: 'password',
                  containerClassName: 'mt-4',
                  hasErrors: !!errors.password,
                }}
                inputProps={field}
              />
            )}
          />

          <div className="flex items-center mb-6 mt-4">
            <div className="flex ml-auto">
              <a
                href={ROUTES_PATH_CONFIG.passwordReset}
                className="inline-flex text-xs font-thin text-gray-500 sm:text-sm hover:text-gray-700"
              >
                Passwort vergessen?
              </a>
            </div>
          </div>

          <FormSubmitButton text={t('button.login')} containerClasses="w-full" />
        </form>

        <div className="mt-4 flex w-full items-center">
          <div className="flex-1 border-b border-black" />
          <span className="mx-4">{t('word.or')}</span>
          <div className="flex-1 border-b border-black" />
        </div>

        <div className="mt-4 flex justify-center">
          <button
            onClick={onLoginWithGoogle}
            type="button"
            className="flex align-center justify-center border rounded border-black bg-white hover:bg-gray-100 text-base px-4 py-2 w-full md:w-40"
          >
            <img
              className="inline-block -ml-1 mr-2"
              aria-hidden="true"
              alt=""
              src={googleLogo.src}
              width={22}
              height={22}
            />
            {t('button.withGoogle')}
          </button>
        </div>
      </CenterContent>
    </div>
  );
}
